import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"

import Layout from "../components/layout"
import { Section, Wrapper, Paragraph } from "../components/styles/container"
import { Grid, Col } from "../components/styles/grid"
import Quote from "../components/quote"
import Mail from "../components/mail"

import Upper from "../images/mediation_oben_cropped.jpg"
import BackgroundLetter from "../images/icons/mediation.svg"
import Lower from "../images/mediation_unten_cropped.jpg"

/******************************************************************************************* STYLED
 *************************************************************************************************/

const TransformImg = styled.img`
  /* Width in PX > 1920px */
  @media only screen and (min-width: 121em) {
    transform: translateX(40%);
  }
`

const Relative = styled.div`
  position: relative;
`

const LetterStyle = styled.img`
  max-height: 300px;
  opacity: 0.15;
  z-index: -1;
  position: absolute;
  bottom: -100px;
  right: 20px;
`

const SecondSection = styled(Section)`
  margin-top: 11rem;
  /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    margin-top: 20rem;
  }
`

/******************************************************************************************** COACHING COMPONENT
 **************************************************************************************************/

const Mediation = () => {
  return (
    <Layout>
      <SEO title="Mediation" />
      {/* // ---------------------------------------------------------------------------FIRST SECTION ON MOBILE */}

      <Section className="appear l-disappear">
        <Wrapper className="flex">
          <Grid gap="7">
            <Col>
              <Relative>
                <h2>Mediation</h2>
                <h3>
                  Als Mediatorin unterstütze ich Sie in dem Prozess, eine
                  nachhaltige Lösung zu finden.
                </h3>
                <Paragraph>
                  Sprichwörtlich mit jemandem über Kreuz liegen, an der Arbeit
                  oder im Privaten, kostet enorm viel Kraft. Probleme und
                  Konflikte sind normal, aber leider oft belastend. Als
                  Mediatorin unterstütze ich Sie in dem Prozess, eine
                  nachhaltige Lösung zu finden.
                </Paragraph>
                <Paragraph>
                  Die Entscheidungsfindung liegt bei den Konfliktparteien. Meine
                  Grundprinzipien sind Vertraulichkeit und Neutralität. Eine
                  Mediation setzt die freiwillige Teilnahme aller am Konflikt
                  beteiligten Parteien voraus. Wenn das nicht möglich ist, kann
                  ein Konfliktcoaching mit einer Person stattfinden.
                </Paragraph>
              </Relative>
            </Col>
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Christina Pabst"
                  src={Upper}
                />
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </Section>

      {/* // ---------------------------------------------------------------------------FIRST SECTION ON DESKTOP */}

      <Section className="disappear l-appear">
        <Wrapper className="flex">
          <Grid l="2" gap="7">
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Christina Pabst"
                  src={Upper}
                />
              </div>
            </Col>
            <Col>
              <Relative>
                <h2>Mediation</h2>
                <h3>
                  Als Mediatorin unterstütze ich Sie in dem Prozess, eine
                  nachhaltige Lösung zu finden.
                </h3>
                <LetterStyle src={BackgroundLetter} />
                <Paragraph>
                  Sprichwörtlich mit jemandem über Kreuz liegen, an der Arbeit
                  oder im Privaten, kostet enorm viel Kraft. Probleme und
                  Konflikte sind normal, aber leider oft belastend. Als
                  Mediatorin unterstütze ich Sie in dem Prozess, eine
                  nachhaltige Lösung zu finden. <br />
                  <br /> Die Entscheidungsfindung liegt bei den
                  Konfliktparteien. Meine Grundprinzipien sind Vertraulichkeit
                  und Neutralität. Eine Mediation setzt die freiwillige
                  Teilnahme aller am Konflikt beteiligten Parteien voraus. Wenn
                  das nicht möglich ist, kann ein Konfliktcoaching mit einer
                  Person stattfinden.
                </Paragraph>
              </Relative>
            </Col>
          </Grid>
        </Wrapper>
      </Section>

      {/* // ---------------------------------------------------------------------------SECOND SECTION */}

      <SecondSection className="content-visibility">
        <Wrapper className="flex">
          <Grid l="2" gap="7">
            <Col>
              <h2>Wie kann Mediation helfen?</h2>
              <Paragraph>
                Eine Mediation oder ein Konfliktcoaching ermöglichen eine andere
                Kommunikation miteinander. Oft entstehen daraus weitere
                Handlungsoptionen und ein konstruktiver Umgang mit der Situation
                wird erreicht. <br />
                Mediationen können bei Ihnen oder an einem neutralen Ort
                stattfinden. Konfliktcoaching auch gern online oder Outdoor.
              </Paragraph>
              <Quote author="Isaac Newton">
                “Die Menschen bauen zu viele Mauern und zu wenig Brücken."
              </Quote>
              <Mail />
            </Col>
            <Col>
              <div className="flex h-center l-h-start">
                <TransformImg
                  className="img-opacity v-img"
                  alt="Eine Zeichnung auf einem Plakat"
                  src={Lower}
                />
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </SecondSection>
    </Layout>
  )
}

export default Mediation
